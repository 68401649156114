/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */




(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
		$('#top-footer section:first-child').addClass('col-md-4');
		$('#top-footer section:last-child').addClass('col-md-6 col-lg-5');
		$('.navbar-collapse').addClass('col-md-6');
		if(!($('body').hasClass('home'))) {
			$('.slider').bxSlider({
				auto:true,
				mode: 'fade'
			 });
		}
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
       $(window).load(function() {

       	    homeSlider();
			 $('#flexslider1').addClass('left-img hidden-xs hidden-sm col-md-6');
			 $('#flexslider2').addClass('right-img col-xs-12 col-sm-6 hidden-md col-lg-3');

       	});
	  },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
	'projects' : {
		init: function() {
			$('#Container').mixItUp({
				load: {
					filter: 'all' 
				},
			});
		}
	}
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
	// var id;
	// $(document).ready(function() {
	// 	doneResizing();
	// });
	// $(window).resize(function() {
	// 	clearTimeout(id);
	// 	id = setTimeout(doneResizing, 500);
		
	// });

	// function doneResizing(){
	// 	if($('body').hasClass('home')) {
	// 		slideContentCenter();
	// 	}
		
	// }
	// function slideContentCenter() {
		
	// 	var winwidth = $(window).width();
	// 	var slide_contnt_ht = $('.slide-content').height();
	// 	$('.right-img,.left-img').css('height','auto');
	// 	$('.right-img img,.left-img img').each(function() {
	// 		$(this).css('height','auto');
	// 	});
	// 	if(winwidth<768) {
	// 		var container_pos = $('.home-banner').height();
	// 		var contnr_cntr = container_pos/2;
	// 		var slide_contnt_cntr = slide_contnt_ht/2;
	// 		var right_pos = $('.right-img').offset().top;
	// 		var left_pos = $('.left-img').offset().top;
	// 		var slide_content_top_pos = (right_pos-left_pos)-slide_contnt_cntr;
	// 		if(slide_content_top_pos<25) {
	// 			slide_content_top_pos = 25;	
	// 		} 
	// 		$(".slide-content").css({ top: slide_content_top_pos+'px' });
	// 	} else if(winwidth>=768 && winwidth<991) {
	// 		var right_img_ht = slide_contnt_ht+48;
	// 		$('.slide-content').css('top', 'auto');
	// 		$('.right-img').css({
	// 			'height' : right_img_ht+'px',
	// 			'overflow' : 'hidden'
	// 		});
	// 	} else {
	// 		$('.slide-content').css('top', 'auto');
	// 		var img_ht = slide_contnt_ht+104;
	// 		if(img_ht<507) {
	// 			img_ht = 507;
	// 		}
	// 		$('.right-img,.left-img').css({
	// 			'height' : img_ht+'px',
	// 			'overflow' : 'hidden'
	// 		});
	// 		$('.right-img img,.left-img img').each(function() {
	// 			$(this).css('height',img_ht);
	// 		});
	// 	}
	// }

	function homeSlider() {
		// $('#right-slider').bxSlider({
		// 	pager: false,
		// 	controls: false,
		// 	auto: true,
		// 	infiniteLoop: true,
		// 	responsive: true,
		// 	mode: 'fade'

		//  });

		// $('#left-slider').bxSlider({
		// 	pager: false,
		// 	controls: false,
		// 	auto: true,
		// 	infiniteLoop: true,
		// 	responsive: true,
		// 	mode: 'fade'
		//  });


        $('.flexslider').flexslider({
        	controlNav: false,
        	directionNav: false,
        	slideshowSpeed: 4000
        });


	}


})(jQuery); // Fully reference jQuery after this point.

